// src/components/hero/hero.js

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useWindowHeight } from "@react-hook/window-size"
import { HeadlineH1 } from "../headline"
import { BannerHeadlineRow } from "../rows"
import {BannerSection} from "../sections"

export const HeroImage = ({ caption }) => {
  const height = useWindowHeight()
  const display = height < 480 ? "none" : "block"

  const dataImage = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero/pepe-juergens.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 60) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroSM: file(relativePath: { eq: "hero/pepe-juergens-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 60) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroMD: file(relativePath: { eq: "hero/pepe-juergens-md.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 60) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroLG: file(relativePath: { eq: "hero/pepe-juergens-lg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2200, quality: 60) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroXL: file(relativePath: { eq: "hero/pepe-juergens-xl.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3000, quality: 60) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const sources = [
    {
      ...dataImage.heroXL.childImageSharp.fluid,
      media: `(min-width: 1280px)`,
    },
    {
      ...dataImage.heroLG.childImageSharp.fluid,
      media: `(min-width: 1024px)`,
    },
    {
      ...dataImage.heroMD.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
    {
      ...dataImage.heroSM.childImageSharp.fluid,
      media: `(min-width: 640px)`,
    },
    {
      ...dataImage.hero.childImageSharp.fluid,
    },
  ]

  return (
    <>
      <Img
        fluid={sources}
        style={{
          position: "absolute",
          display,
          top: "0",
          left: "0",
          zIndex: "0",
          height: "100%",
          width: "100%",
        }}
        objectFit="contain"
        objectPosition="top 50%"
        alt="Pepe Jürgens - User Experience"
      />
    </>
  )
}

const Hero = ({ banner, children }) => {
  return (
    <BannerSection className="relative z-10 min-h-700 h-screen-90vh bg-warm-gray-100">
      <HeroImage />

      <BannerHeadlineRow className="relative z-10 lg:w-1/2 ">
        <HeadlineH1 {...banner} />
      </BannerHeadlineRow>

      {/* headline vertical centered version */}
      {/* <div className="pt-16 lg:pt-0 lg:flex lg:items-center lg:justify-center lg:ml-1/12 lg:h-2/3 lg:w-1/2">
          <HeadlineH1 {...banner} />
          </div> 
        */}
    </BannerSection>
  )
}

export default Hero
