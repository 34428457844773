// src/pages/index.js

import "../styles/tailwind.css"

import React, { useEffect, useRef, useState } from "react"
import data from "../data/yaml/front_page.yaml"
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import { getPage } from "../utils/helper"
import {
  UntilSmartphoneWidthQuery,
  FromTabletWidthQuery,
} from "../utils/reactResponsive"
import HeadMetadata from "../components/headMetadata"
import HeaderBar from "../components/headerBar"
import Footer from "../components/footer"
// import Testemonials from "../components/testemonials"
import GraphicsSVG from "../components/graphicsSVG"
import { BrightSection, DarkSection } from "../components/sections"
import { DiscList } from "../components/lists"
import { LinkButtonSecondary } from "../components/buttons"
import { ARROW_RIGHT_ICON } from "../components/icons"
import { orderMapping } from "../components/footer/footer"
import Hero from "../components/hero"
import ContactSection from "../components/contact"
import { HeadlineH4 } from "../components/headline"
import { IndentedRow, FullRow } from "../components/rows"

// const captionClasses =
//   "w-10/12 pb-16 mx-auto md:pb-20 lg:pb-28 md:mr-0 md:ml-auto md:w-1/2 text-sm sm:text-smSM md:text-smMD lg:text-smLG"
const captionClasses =
    "w-full pb-16 mx-auto md:pb-20 lg:pb-28  ssm:w-10/12 /sm:w-8/12  text-sm sm:text-smSM md:text-smMD lg:text-smLG"
const ApproachSection = ({ handleOverlabHeaderbar }) => {
  const rowRef = useRef(null)
  const {
    approach: { headline, text },
    banner: { caption, captionShort },
  } = data

  // for headerbar change background-color from transparent to white
  useEffect(() => {
    const handleScroll = () => {
      const { top } = rowRef.current.getBoundingClientRect()

      if (top > 50) {
        handleOverlabHeaderbar(true)
      } else {
        handleOverlabHeaderbar(false)
      }
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  })

  return (
    <div ref={rowRef}>
      <DarkSection className="pt-4 pb-16 md:pt-6 lg:pt-8 md:pb-20">
        {/* capion for the image */}
        <UntilSmartphoneWidthQuery>
          <div className={captionClasses}>{captionShort}</div>
        </UntilSmartphoneWidthQuery>
        <FromTabletWidthQuery>
          <div className={captionClasses}>{caption}</div>
        </FromTabletWidthQuery>
        <IndentedRow>
          <h3 className="text-primary-400">{headline}</h3>
          <p>{text}</p>
        </IndentedRow>
      </DarkSection>
    </div>
  )
}

const ServicesSection = () => {
  const { help } = data
  return (
    <BrightSection className="pb-8">
      <FullRow className="pb-20 mx-auto lg:pb-28">
        <HeadlineH4 {...help} />
      </FullRow>
      <ServiceQuotes />
    </BrightSection>
  )
}

const ServiceQuotes = () => {
  const { pages } = useSiteMetadata()
  const {
    help: { list },
  } = data

  return (
    <>
      {list.map(({ quote, headline, text, pageId }, index) => {
        const { labelLong, url } = getPage(pageId, pages)

        return (
          <div key={index} className="pb-24 md:pb-32">
            <div className="w-full pb-6 mx-auto md:pb-10 sm:w-10/12 lg:w-8/12 ">
              <blockquote className="text-center">{quote}</blockquote>
            </div>

            <IndentedRow>
              <h2 className="relative md:font-semibold">
                <span className="font-normal sm:absolute sm:-left-5 lg:-left-7">
                  {orderMapping[index]} &nbsp;
                </span>
                {headline}
              </h2>
              <p className="pb-4">{text}</p>
              <LinkButtonSecondary
                className=""
                url={url}
                label={labelLong}
                useAsLabelProp="labelLong"
                iconId={ARROW_RIGHT_ICON}
              />
            </IndentedRow>
          </div>
        )
      })}
    </>
  )
}

const ExpertSection = () => {
  const {
    expert: { headline, text1, text2, list },
  } = data
  return (
    <DarkSection className="pb-8 pt-18">
      <IndentedRow>
        <h3
          className="pb-4 text-primary-500"
          dangerouslySetInnerHTML={{ __html: headline }}
        />
        <p className="pb-4">{text1}</p>
        <DiscList list={list} />
        <p className="pb-18">{text2}</p>
        <Logos {...data.awards} />
        <Logos {...data.clients} />
      </IndentedRow>
    </DarkSection>
  )
}

const Logos = ({ headline, list }) => (
  <>
    <h3 className="pb-10 ml-1/12s sm:ml-0">{headline}</h3>
    <ul className="flex flex-wrap pb-10">
      {list.map(({ logoId, size }, index) => (
        <li key={index} className="flex justify-center w-1/3 py-6">
          <GraphicsSVG
            width={`${size === undefined ? "60%" : size}`}
            key={index}
            id={`${logoId}_gray`}
          />
        </li>
      ))}
    </ul>
  </>
)

const TeachingSection = () => {
  const {
    teaching: { headline, textHtml },
  } = data
  return (
    <BrightSection className="pt-0 pb-24 md:pt-0 lg:pt-0 md:pb-30 lg:pb-32">
      <IndentedRow>
        <h3 className="font-bold md:font-semibold">{headline}</h3>
        <p dangerouslySetInnerHTML={{ __html: textHtml }} />
      </IndentedRow>
    </BrightSection>
  )
}

export default () => {
  const [overlabHeaderbar, setOverlabHeaderbar] = useState(null)
  const handleOverlabHeaderbar = (value) => setOverlabHeaderbar(value)

  return (
    <>
      <HeadMetadata {...data.metadata} />
      <HeaderBar overlabHeaderbar={overlabHeaderbar} />
      <Hero {...data} />
      <ApproachSection handleOverlabHeaderbar={handleOverlabHeaderbar} />
      <ServicesSection />
      <ExpertSection />
      <ContactSection />
      <TeachingSection />
      {/* <Testemonials /> */}
      <Footer />
    </>
  )
}
